:root{
    --white: #FFFFFF;
    --black: #1D1D1D;
    --green:  #12403C;
    --yellow: #EAD295;
    --blue: #B8DFEC;
    --dealer: #1D1E21;
    --grey: #717D7C;
    --light-blue: #ECF6F9;
    --light-yellow: #F9F1DF;
    --light-grey: #F4F4F4;
    --light-green: #EEF9EC;
    --extra-green: #092927;

}
html{
    width: 100%;
    max-width: 100%;
}
body{
    font-family: 'Montserrat', 'Oswald', sans-serif;
    margin: 0;
    min-height: 100vh;
    max-width: 100vw;
    width: 100%;
    overflow-x: hidden;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a{
    text-decoration: none;
    cursor: pointer;
}
button{
    outline: none;
    cursor: pointer;
    padding: 16px 20px;
    background-color: transparent;
    border: 1.5px solid var(--dealer);
}
button, button a {
    color: var(--dealer);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
}
button:hover{
    background-color: var(--green);
    border: 1.5px solid transparent;
}
button:hover, button:hover a{
    color: var(--white);
}
button:active{
    background-color: var(--extra-green);
    color: var(--white);
    border: 1.5px solid transparent;
}
button:disabled{
    background-color: var(--grey);
    color: var(--light-grey);
    border: 1.5px solid transparent;
}

.row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.flex-start{
    align-items: flex-start;
}
.space-between{
    justify-content: space-between;
}

#root{
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/*prepage*/
.prepage-container{
    width: 100vw;
    height: 100vh;
    background-color: var(--white);
}
.prepage-part{
    border: 2px solid var(--green);
    width: 100%;
    height: 100%;
}
.prepage-part:last-child{
    border-left: none;
}
.prepage-part-title{
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 42px;
    line-height: 62px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 17px;
}
.prepage-part-address,
.prepage-part-phone {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.04em;
}
.prepage-part-btn{
    margin-top: 28px;
}

/*header*/
header .content nav .close{
    display: none;
}
.header-inner{
    background: var(--green);
    border-bottom: 2px solid var(--green);
    padding: 20px 36px;
}
.header-inner.simplify{
    position: fixed;
    top:0;
    left: 0;
    width:100%;
    height: 75px;
    z-index: 99;
    display: flex;
    align-items: center;
}
.header-inner.simplify .content{
    flex: 1;
}
.header-inner.simplify > .hide-content{
    display: none;
}
.header-inner > .hide-content{
    padding-bottom: 26px;
}
header a{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    color: var(--yellow);
    text-align: center;
    display: flex;
}
header a:not([target="_blank"]):hover{
    -webkit-box-shadow: 0 2px 1px var(--yellow);
    -moz-box-shadow: 0 2px 1px var(--yellow);
    box-shadow: 0 2px 1px var(--yellow);
}
header .navigation-list{
    gap: 47px;
    padding: 0 47px;
}
header .content .logo,
header .content .hamburger,
header .content .hide-content{
    display: none;
}
.reserve-now-btn{
    display: none;
    width: 182px;
    height: 182px;
    border-radius: 50%;
    background-color: var(--green);
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 31px;
    line-height: 41px;
    text-align: center;
    text-transform: uppercase;
    color: var(--yellow);
    position: fixed;
    z-index: 99;
    bottom: 41px;
    right: 41px;
    cursor: pointer;
}
.reserve-now-btn:hover{
    background-color: var(--extra-green);
}
.reserve-now-btn.hidden{
    display: flex;
}

.mode-togglers-container{
    display: flex;
}
.mode-toggler{
    background-color: transparent;
    border: 1px solid var(--yellow);
    color: var(--yellow);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 4px 14px;
    cursor: pointer;
}
.mode-toggler.active{
    background-color: var(--yellow);
    color: var(--green);
}
header .header-inner > .hide-content{
    position: relative;
}
header .header-inner > .hide-content .mode-togglers-container{
    position: absolute;
    top: 0;
    right: 0;
}
header .header-inner > .hide-content .language-togglers-container{
    position: absolute;
    top: 0;
    left: 0;
}

/*ticker*/
.service-ticker {
    height: 112px;
    background-color: var(--green);
    display: flex;
}

/*404*/
.page-not-found p{
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: var(--green);
    margin-top: -40px;
}
.page-not-found img{
    max-width: 90%;
}

/*about-us*/
#about_us .main-image{
    display: flex;
}
#about_us img{
    width: 100%;
}
#about_us .service-ticker{
    position: relative;
}
#about_us .service-ticker img{
    height: 56px;
}

.about-information{
    padding: 190px 0;
    background-color: var(--light-yellow);
    background-image: url(../assets/about_us_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.about-information-inner{
    max-width: 546px;
}
.about-information-text{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: var(--black);
    margin-bottom: 17px;
}
.about-information-hashtag{
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 54px;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase;
    color: var(--black);
    margin-bottom: 23px;
}

/*headline*/
.headline{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 75px;
    line-height: 111px;
    text-transform: uppercase;
    color: var(--yellow);
    height: 229px;
    background-color: var(--green);
}
.headline .dash{
    width: 80px;
    height: 2px;
    background-color: var(--yellow);
}
.headline span{
    display: inline-block;
    margin: 0 71px;
}

/*services*/
.services{
    display: flex;
}
.services .image-preview{
    width: 36%;
    position: relative;
}
.services .image-preview img{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
}
.services .services-navigation{
    padding: 58px 0;
    width: 32%;
    border-left: 2px solid var(--green);
    border-right: 2px solid var(--green);
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.services .services-navigation-item{
    min-height: 70px;
    cursor: pointer;
    margin-bottom: 12px;
    width: calc(100% + 2px);
    padding: 0 55px;
}
.services .services-navigation-item-title{
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 47px;
    text-transform: uppercase;
    color: var(--black);
}
.services .services-navigation-item:last-child{
    margin-bottom: 0;
}
.services .services-navigation-item.active{
    -webkit-box-shadow: 0 2px 1px var(--green), 0 -2px 1px var(--green);
    -moz-box-shadow: 0 2px 1px var(--green), 0 -2px 1px var(--green);
    box-shadow: 0 2px 1px var(--green), 0 -2px 1px var(--green);
    background-color: var(--light-grey);
}
.services .services-navigation-item-content{
    display:none;
}
.services .services-content{
    width: 32%;
    padding: 0 52px;
    background-color: var(--light-grey);
}
.services .services-content-description{
    margin-bottom: 32px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--black);
}
.services .services-content-address{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: var(--black);
    margin-bottom: 6px;
}
.services .services-content-address:last-child{
    margin-bottom: 32px;
}
.services-navigation-item-content-description{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: var(--black);
    margin-bottom: 26px;
}
.services-navigation-item-content-addresses{
    margin-bottom: 26px;
}
.services-navigation-item-content-address{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
}
.services-navigation-item-content button{
    width: 100%;
}

/*price-list*/
.price-list{
    background: var(--light-green);
    border-bottom: 2px solid var(--green);
    padding: 135px 0;
}
.price-list-inner{
    margin: 0 auto;
    max-width: 943px;
}
.price-list-inner button{
    margin-top: 42px;
}
.price-list-item{
    border-top: 1px solid var(--green);
    padding: 0 10px;
    width: 100%;
    min-height: 80px;
    cursor: pointer;
}
.price-list-item-preview{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 23px 0;
}
.price-list-item-preview img{
    transform: rotate(180deg);
}
.price-list-item-title{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    color: var(--black)
}
.price-list-item-services{
    display: none;
}
.price-list-item-service{
    -webkit-box-shadow: 0 -2px 1px rgba(18, 64, 60, 0.2);;
    -moz-box-shadow: 0 -2px 1px rgba(18, 64, 60, 0.2);;
    box-shadow: 0 -2px 1px rgba(18, 64, 60, 0.2);;
    padding-top: 23px;
    padding-bottom: 20px;
}
.price-list-item.active .price-list-item-services{
    display: flex;
}
.price-list-item.active .price-list-item-preview img{
    transform: rotate(0deg);
}
.price-list-item-service-title,
.price-list-item-service-price{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: var(--black);
    margin-bottom: 10px;
}
.price-list-item-service-description,
.price-list-item-service-price span{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.04em;
    color: var(--black);
}
.price-list-item-service-price span{
    margin-left: 4px;
}
.price-list-item-service-text-content{
    width: 100%;
    display: flex;
}

/*out works*/
.our-works .react-slideshow-container img{
    height: 480px;
}
.our-works .react-slideshow-wrapper .images-wrap>div{
    height: 480px;
}
.slider-navigation{
    width: 41px;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-slideshow-wrapper.slide{
    overflow: visible;
}
.slider-navigation.view{
    width: 74px;
    background-color: rgba(0,0,0,1);
}
.slider-navigation:hover{
    background-color: rgba(0,0,0,.6);
}
.slider-navigation.view:hover{
    background-color: rgba(0,0,0,1);
}
.slider-navigation img{
    width:18px;
    opacity: 0.4;
}
.slider-navigation.view img{
    width: 23px;
    opacity: 1;
}
.slider-navigation:hover img{
    opacity: 1;
}
.slider-navigation[data-type="prev"] img,
.react-simple-image-viewer__previous img{
    transform: rotate(180deg);
}
#ReactSimpleImageViewer{
    background-color: rgba(0,0,0,.8);
    z-index: 11;
}
.react-simple-image-viewer__slide img{
    height: 80% !important;
}
.react-simple-image-viewer__previous,
.react-simple-image-viewer__next{
    height: 100%;
    padding: 0;
}
.react-simple-image-viewer__previous .slider-navigation,
.react-simple-image-viewer__next .slider-navigation{
    background-color: rgba(0,0,0, .9);
}

/*our team*/
.our-team{
    padding: 158px 0 59px;
    background-color: var(--light-yellow);
    position: relative;
}
.our-team-employee{
    position: relative;
    border-left: 2px solid var(--green);
    aspect-ratio: 720/880;
    display: flex;
}
.our-team-employee img{
    aspect-ratio: 720/880;
    width: 100%;
}
.our-team .indicators{
    column-gap: 38px;
    margin-top: 59px !important;
    padding: 0 20px;
}
.departments-indicator{
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 41px;
    text-transform: uppercase;
    color: var(--green);
}
.departments-indicator:not(.active){
    cursor: pointer;
}
.departments-indicator.active{
    font-weight: 500;
}
.our-team .nav-btn{
    position: absolute;
    bottom: 64px;
    cursor: pointer;
}
.our-team .nav-btn.go-back{
    transform: rotate(180deg);
    left: 75px;
}
.our-team .nav-btn.go-next{
    right: 75px;
}
.active-department-title{
    padding: 22px 27px;
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 41px;
    text-transform: uppercase;
    color: var(--black);
    border: 2px solid var(--green);
    background-color: var(--white);
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    display: none;
    white-space: nowrap;
}
.active-department-title.active{
    display: block;
}

/*press*/
.press-article{
    height: 480px;
    position: relative;
}
.press-article img{
    height: 480px;
}
.press-article-text-content{
    padding: 40px 45px;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left: 0;
    align-items: flex-start;
}
.press-article-title{
    color: var(--white);
    font-family: 'Oswald', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 50px;
    text-transform: uppercase;
    margin-top: auto;
}
.press-article-description{
    color: var(--white);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    margin-top: 16px;
}
.press-article-date{
    color: var(--white);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
}

/*instagram*/
.instagram-post,
.instagram-post img{
    object-fit: cover;
    width: 100%;
}
.instagram-post{
    display: flex;
}
.instagram-link-wrapper{
    height: 222px;
    background-color: var(--light-green);
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 54px;
    text-align: center;
}
.instagram-link-wrapper .insta_link{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}
.instagram-link-wrapper .insta_link img{
    width: 48px;
}
.instagram-link-wrapper span{
    color: var(--green);
}
.instagram-link-wrapper span:hover{
    -webkit-box-shadow: 0 2px 1px var(--green);
    -moz-box-shadow: 0 2px 1px var(--green);
    box-shadow: 0 2px 1px var(--green);
}

/*partners*/
.partners{
    padding: 79px 0 65px;
    gap: 55px;
    background-color: var(--light-yellow);
    border-top: 2px solid var(--green);
}
.partners img{
    width: 100%;
}
.partners .partners-mob{
    display: none;
}

/*addresses*/
.addresses .prepage-container{
    width: 100%;
    height: unset;
    background-color: var(--light-grey);
    position: relative;
}
.addresses .prepage-container .prepage-part{
    border: none;
    aspect-ratio: 1/1;
    height: unset;
}
.addresses .prepage-container img{
    position: absolute;
    height: 100%;
    transition: opacity .4s ease-in-out;
    pointer-events: none;
    opacity: 0;
}
.addresses .prepage-container .prepage-part .prepage-part-adress{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.04em;
    max-width: 300px;
}
.addresses .prepage-container .prepage-part .prepage-part-btn{
    margin-top: unset;
}
.addresses .prepage-container .prepage-part .prepage-part-text{
    height: calc(100% - 100px);
}
.addresses .prepage-container .prepage-part{
    justify-content: flex-start;
    height: calc(100% - 100px);
}
.addresses .prepage-container .prepage-part.club{
    border-right: 1px solid var(--green);
}
.addresses .prepage-container .prepage-part.club ~ img{
    top:0;
    right: 0;
}
.addresses .prepage-container .prepage-part.club:hover ~ img.club-preview{
    pointer-events: all;
    opacity: 1;
}
.addresses .prepage-container .prepage-part.clinic{
    border-left: 1px solid var(--green);
}
.addresses .prepage-container .prepage-part.clinic ~ img{
    top:0;
    left: 0;
}
.addresses .prepage-container .prepage-part.clinic:hover ~ img.clinic-preview{
    pointer-events: all;
    opacity: 1;
}

/*footer*/
footer{
    padding: 61px 0;
    background: var(--light-grey);
    border-top: 2px solid var(--green);
}
footer .copyright{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.04em;
    color: var(--green);
}

@media screen and (max-width: 1710px) {
    .our-team .nav-btn{
        bottom: 84px;
    }
}

@media screen and (max-width: 1400px) {
    .active-department-title{
        padding: 18px 23px;
        font-size: 25px;
        line-height: 37px;
    }
}

@media screen and (max-width: 1200px) {
    .active-department-title{
        padding: 14px 18px;
        font-size: 22px;
        line-height: 33px;
    }
}

@media screen and (max-width: 1115px) {
    .our-team .nav-btn{
        bottom: 106px;
    }
}

@media screen and (max-width: 991px) {
    .row{
        flex-direction: column;
    }

    .prepage-part:last-child{
        border-left: 2px solid var(--green);
        border-top: none;
    }
    .prepage-part-title{
        font-size: 27px;
        line-height: 40px;
    }
    .prepage-part-address,
    .prepage-part-phone {
        font-size: 20px;
        line-height: 24px;
    }
    .mode-toggler{
        font-size: 16px;
        line-height: 20px;
        padding: 9px 14px;
    }
    .header-inner{
        padding: 20px;
    }
    header .content{
        display: flex;
        flex-direction: column;
        position: relative;
    }
    header .content .logo,
    header .content .hamburger,
    header .content .hide-content{
        display: flex;
    }
    header .content .hamburger{
        cursor: pointer;
        z-index: 1;
    }
    header .content nav{
        background-color: var(--green);
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        top:0;
        z-index: 1;
    }
    header .content nav .close{
        display: block;
        position: absolute;
        top: 36px;
        left: 17px;
    }
    header .content nav.navigation-list.hidden,
    .header-inner > .hide-content,
    .header-inner.simplify .content > .hide-content,
    header .content .phone_link{
        display: none;
    }
    .header-inner .content .logo{
        height: 54px;
        margin-bottom: 8px;
    }
    .header-inner.simplify .content .logo{
        margin-bottom: 0;
    }
    header .content .hide-content{
        width: 100%;
    }
    header .content .hamburger{
        position: absolute;
        top: 21px;
        left: 0;
    }
    header .content .insta_link{
        position: absolute;
        top: 18px;
        right: 0;
    }
    .reserve-now-btn{
        width: 93px;
        height: 93px;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        bottom: 30px;
        right: 30px;
    }
    .service-ticker {
        height: 84px;
    }
    #about_us .service-ticker img{
        height: 42px;
    }
    .headline{
        font-weight: 300;
        font-size: 33px;
        line-height: 49px;
        color: var(--yellow);
        height: 126px;
    }
    .headline .dash{
        width: 47px;
        height: 1px;
    }
    .headline span{
        margin: 0 32px;
    }
    .about-information{
        padding: 99px 0;
        background-position: 23px center;
        background-image: url("../assets/about_us_bg_mob.png");
    }
    .about-information-inner{
        padding: 0 20px;
    }
    .about-information-text{
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 23px;
    }
    .about-information-hashtag{
        font-size: 43px;
        line-height: 63px;
        margin-bottom: 22px;
    }
    .about-information-inner button {
        width: 100%;
    }

    .services .image-preview,
    .services .services-content{
        display: none;
    }
    .services .services-navigation{
        width: 100%;
        padding: 41px 0;
        border: none
    }
    .services .services-navigation.selected-top{
        padding-top: 0;
    }
    .services .services-navigation.selected-bottom{
        padding-bottom: 0;
    }
    .services .services-navigation-item {
        min-height: unset;
        margin-bottom: 30px;
        width: 100%;
        padding: 0 24px;
    }
    .services .services-navigation-item-title {
        font-weight: 300;
        font-size: 27px;
        line-height: 40px;
    }
    .services .services-navigation-item.active {
        padding: 41px 24px;
    }
    .services .services-navigation-item.active .services-navigation-item-content {
        display: flex;
    }
    .services .services-navigation-item.active .services-navigation-item-title {
        margin-bottom: 26px;
    }
    .departments-container{
        background-color: var(--white);
        border-bottom: 2px solid var(--green);
        width: 100%;
        height: 75px;
        position: absolute;
        top: 0;
        left: 0;
        text-transform: uppercase;
    }
    .selected-department{
        font-family: 'Oswald', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 39px;
        color: var(--black);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 75px;
        text-transform: uppercase;
    }
    .selected-department img{
        margin-left: 17px;
    }
    .departments-container.hidden .selected-department img{
        transform: rotate(180deg);
    }
    .departments-container.hidden .departments-list{
        display: none;
    }
    .departments-container .departments-list{
        padding: 44px 24px;
        background-color: var(--white);
        border-bottom: 2px solid var(--green);
    }
    .departments-container .departments-list .departments-list-item{
        font-family: 'Oswald', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 27px;
        line-height: 40px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: var(--black);
        margin-bottom: 30px;
    }
    .departments-container .departments-list .departments-list-item:last-child{
        margin-bottom: 0;
    }
    .departments-container .departments-list .departments-list-item.active{
        font-weight: 400;
    }
    .department-employee{
        aspect-ratio: 720/880;
    }
    .department-employee img{
        width: calc(100% + 4px);
        height: 100%;
    }
    .price-list{
        padding: 0 0 100px;
    }
    .price-list-inner{
        max-width: unset;
    }
    .price-list-inner button{
        width: calc(100% - 48px);
    }
    .price-list-item{
        border-top: none;
        padding: 0;
    }
    .price-list-item.active{
        border-bottom: 1px solid var(--green);
    }
    .price-list-item-preview{
        padding: 23px 24px;
        border-bottom: 1px solid var(--green);
    }
    .price-list-item-services{
        padding: 0 24px;
    }
    .our-works .image-wrapper,
    .press .press-article,
    .instagram .instagram-post{
        border-bottom: 2px solid var(--green);
        aspect-ratio: 1/1;
        display: flex;
        height: unset;
    }
    .press .press-article{
        border-bottom: unset;
    }
    .our-works .image-wrapper img,
    .instagram .instagram-post img,
    .press .press-article img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .our-works .button-wrapper,
    .press .button-wrapper{
        padding: 40px 24px 100px;
        background-color: var(--white);
    }
    .our-works .button-wrapper button,
    .press .button-wrapper button{
        width: 100%;
        margin: 0 auto;
    }
    .press-article-text-content{
        padding: 24px;
    }
    .press-article-title{
        font-size: 32px;
        line-height: 47px;
    }
    .press-article-description{
        font-size: 24px;
        line-height: 29px;
        margin-top: 14px;
    }
    .press-article-date{
        font-size: 13px;
        line-height: 16px;
        margin-top: 14px;
    }
    .instagram-link-wrapper{
        height: 179px;
        background-color: var(--light-yellow);
        font-size: 23px;
        line-height: 29px;
        padding: 38px 25px;
        justify-content: flex-start;
    }
    .instagram-link-wrapper .insta_link{
        gap: 13px;
    }
    .instagram-link-wrapper .insta_link img{
        width: 24px;
    }
    .partners{
        padding: 35px 21px 32px;
        background-color: var(--white);
    }
    .partners img:not(.partners-mob){
        display: none;
    }
    .partners .partners-mob{
        display: block;
    }
    .addresses .prepage-container .prepage-part {
         justify-content: center;
    }
    .addresses .prepage-container .prepage-part .prepage-part-adress{
        font-weight: 300;
        font-size: 20px;
        line-height: 24px;
        max-width: 183px;
    }
    .addresses .prepage-container .prepage-part .prepage-part-text {
        height: unset;
        margin-bottom: 24px;
    }
    .addresses .prepage-container .prepage-part.club{
        border-right: none;
        border-bottom: 1px solid var(--green);
    }
    .addresses .prepage-container .prepage-part.clinic{
        border-left: none;
        border-top: 1px solid var(--green);
    }
    .addresses .prepage-container img {
        height: unset;
        width: 100%;
    }
    .addresses .prepage-container .prepage-part.club ~ img{
        top:unset;
        bottom: 0;
    }
    .addresses .prepage-container .prepage-part.clinic ~ img{
        top:0;
        left: 0;
    }
    footer{
        padding: 47px 0;
    }
}

@media screen and (max-width: 500px) {
    .page-not-found p{
        font-family: 'Montserrat', sans-serif;
        font-style: italic;
        font-weight: 400;
        font-size: 30px;
        line-height: 39px;
        text-align: center;
        color: var(--green);
        margin-top: -20px;
    }
    #about_us .main-image{
        aspect-ratio: 1/1;
        position: relative;
        overflow: hidden;
    }
    #about_us .main-image img{
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        max-width: 100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 325px) {
    .header-inner .content .logo {
        height: 40px;
    }
    header .content .insta_link {
        top: 12px;
    }

    header .content .hamburger {
        top: 14px;
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
        visibility: visible;
    }

    100% {
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
}


.ticker {
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 30s;
}
.ticker__item {
    display: inline-block;
    font-size: 2rem;
}